import { Injectable } from '@angular/core';
import { ApiService } from '@capturum/api';
import { Observable, Subject, tap } from 'rxjs';
import { MessagesCount } from '@shared/models/messages-count.model';

@Injectable({
  providedIn: 'root',
})
export class CampaignsCounterApiService extends ApiService<any> {
  public campaignsCount$: Subject<number> = new Subject<number>();
  protected endpoint = 'campaign';

  public getCampaignCount(): Observable<MessagesCount> {
    return this.apiHttp.get<{ data: number[] }>(`/${this.endpoint}/unread`).pipe(
      tap((count) => {
        return this.campaignsCount$.next(count.data[0]);
      }),
    );
  }
}
